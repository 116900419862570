import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputIcon from "@mui/icons-material/Input";

import { useRequest } from "hooks/useRequest";
import { DevicePageLink, IconLabelWrapper, IconTreeItem, TreeLabelWrapper } from "./MapSidebar.style";

export const DeviceItemLabel = ({
  title,
  serialNumber,
  onCheck,
  isChecked,
  icon,
  onDeviceLoaded,
  dataFieldStr,
  description,
}) => {
  const { data } = useRequest(isChecked ? `mappingUIHook?sn=${serialNumber}${dataFieldStr}` : "mappingUIHook");

  useEffect(() => {
    onDeviceLoaded?.(serialNumber, data);
  }, [data]);

  return (
    <IconLabelWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Checkbox onChange={onCheck} checked={isChecked} />
      {icon}
      <span style={{ paddingTop: 1 }}>
        {title || (
          <Tooltip title={description} style={{ marginLeft: 2, fontSize: 15 }}>
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </span>
      <DevicePageLink to={`/devices/${serialNumber}`}>
        <InputIcon />
      </DevicePageLink>
    </IconLabelWrapper>
  );
};

DeviceItemLabel.propTypes = {
  title: PropTypes.string,
  serialNumber: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  onDeviceLoaded: PropTypes.func.isRequired,
  dataFieldStr: PropTypes.string.isRequired,
  description: PropTypes.string,
};

DeviceItemLabel.defaultProps = {
  description: "",
  title: "",
};

export const CheckableLabel = ({ title, icon, onCheck, isChecked }) => (
  <IconLabelWrapper
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <Checkbox onChange={onCheck} checked={isChecked} />
    <TreeLabelWrapper>
      {icon}
      <span>{title}</span>
    </TreeLabelWrapper>
  </IconLabelWrapper>
);

CheckableLabel.propTypes = {
  title: PropTypes.string.isRequired,
  onCheck: PropTypes.func,
  isChecked: PropTypes.bool,
  icon: PropTypes.element,
};

CheckableLabel.defaultProps = {
  isChecked: false,
  icon: null,
  onCheck: null,
};

export const TreeIconItem = ({ icon, label, ...rest }) => (
  <IconTreeItem
    label={
      <IconLabelWrapper>
        {icon}
        {label}
      </IconLabelWrapper>
    }
    {...rest}
  />
);

TreeIconItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.element,
};

TreeIconItem.defaultProps = {
  icon: null,
  label: null,
};
