import React from "react";
import Box from "@mui/material/Box";

import { PrimaryButton, SecondButton } from "../common/Buttons";

const FormFactory = (function () {
  let instance = null;

  function FormSingleton() {
    this.handleCancel = undefined;
    this.handleSubmit = undefined;
    this.onSave = undefined;
    this.isEditing = undefined;
    this.Confirmation = undefined;

    // Going to turn off this type checking.  TODO: Should be revisited when we get to TypeScript
    // eslint-disable-next-line react/prop-types
    this.Form = ({ children, isLoading = false }) => (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {children}

        {instance.isEditing && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <PrimaryButton loading={isLoading} onClick={instance.handleSubmit(instance.onSave)}>
              Save
            </PrimaryButton>
            <SecondButton onClick={instance.handleCancel} sx={{ ml: 2 }}>
              Cancel
            </SecondButton>
          </Box>
        )}

        <this.Confirmation />
      </Box>
    );
  }

  return {
    getInstance: function ({ handleCancel, handleSubmit, onSave, isEditing, Confirmation }) {
      if (!instance) {
        instance = new FormSingleton();
        delete instance.constructor;
      }

      instance.handleCancel = handleCancel;
      instance.handleSubmit = handleSubmit;
      instance.onSave = onSave;
      instance.isEditing = isEditing;
      instance.Confirmation = Confirmation;

      return instance;
    },
  };
})();

export default FormFactory;
