import React from "react";
import { Routes, Route } from "react-router-dom";

import { useAuth } from "hooks/useProvideAuth";
import NotFound from "./components/NotFound";
import AuthRoute from "./authRoute";
import AdminWebUserRoute from "./adminWebUserRoute";
import PowerUserRoute from "./powerUserRoute";

const AppRoute = () => {
  const { isPowerUser, isAuthenticated, isAuthLoaded } = useAuth();

  return (
    <Routes>
      {AuthRoute()}

      {!isAuthenticated ? null : isPowerUser ? PowerUserRoute() : AdminWebUserRoute()}

      {/* Enable this after auth is loaded, all available routes are confirmed */}
      {isAuthLoaded && <Route path="*" element={<NotFound />} />}
    </Routes>
  );
};
export default AppRoute;
