import styled from "@emotion/styled";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";

import BREAKPOINTS from "utils/breakpoints";

export const TableWrapper = styled(TableContainer)`
  .hide-mobile {
    ${BREAKPOINTS.sm} {
      display: none;
    }
  }
`;

export const NoDataWrapper = styled(TableCell)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const TableHeaderCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.gray[400]};
`;

export const TableBodyCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span.error-text {
    margin-top: 10px;
    color: ${({ theme }) => theme.palette.error.main};
    white-space: pre-wrap;
    font-size: 13px;
    position: absolute;
    top: 27px;
    left: 0px;
  }
`;

export const ActiveFilter = styled.div`
  font-size: 13px;
`;

export const DateFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input.MuiInputBase-input {
    height: 0px;
  }
`;
