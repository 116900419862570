import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";

import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import { PageSection } from "components/common/Wrapper";
import { changeDeviceStatusFromUI } from "api/DeviceHelpers";
import useConfirm from "hooks/useConfirm";
import { SectionHeader } from "./DeviceDetail.style";

const MEGAEI_REFRESH_MIN_VERSION = "MegaFI-2.4.34";
const EI_REFRESH_MIN_VERSION = "EI-2.4.16";
const RM_MEGAFI_MIN_UPDATE_VERSION = "MegaFI-2.4.44";

const DetailActions = ({ deviceDetail, mutatePendingActions, mutateDetail, launchMissionControl }) => {
  const [getConfirmation, Confirmation] = useConfirm();

  const canRefreshValues = useMemo(() => {
    if (!deviceDetail) {
      return false;
    }

    if (isMegaSoftware(deviceDetail.eiSoftwareVersion)) {
      return compareSoftwareVersions(deviceDetail.eiSoftwareVersion, MEGAEI_REFRESH_MIN_VERSION) >= 0;
    }
    return compareSoftwareVersions(deviceDetail.eiSoftwareVersion, EI_REFRESH_MIN_VERSION) >= 0;
  }, [deviceDetail]);

  const handleRefresh = async () => {
    const isRefreshValues = await getConfirmation({
      title: "Refresh Values",
      content: "Are you sure to refresh values?",
    });

    if (!isRefreshValues) {
      return;
    }

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      getDeviceDetails: true,
    });

    mutatePendingActions?.();
  };

  const handleReboot = async () => {
    const isRebootDevice = await getConfirmation({
      title: "Reboot Device",
      content: "Are you sure? You will lose connectivity while the device reboots.",
    });

    if (!isRebootDevice) {
      return;
    }

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      rebootDevice: true,
    });

    mutatePendingActions?.();
  };

  const handleLocationTrail = async () => {
    const isConfirmed = await getConfirmation({
      title: "Location Tracking",
      content: `Are you sure you want to ${deviceDetail.locationTrailEnabled ? "disable" : "enable"} location tracking`,
    });

    if (!isConfirmed) {
      return;
    }

    const data = deviceDetail.locationTrailEnabled
      ? { disableLocationTrail: true }
      : { enableLocationTrail: true, getDeviceDetails: true };

    await changeDeviceStatusFromUI({
      eiSerialNumber: deviceDetail.eiSerialNumber,
      ...data,
    });

    mutatePendingActions?.();
    mutateDetail?.();
  };

  return (
    <PageSection>
      <SectionHeader>
        <span className="header-title">Device Actions</span>
      </SectionHeader>
      {deviceDetail ? (
        <Box display="flex" flexDirection="column" gap={2} maxWidth={240} margin="auto">
          <Button variant="contained" onClick={handleRefresh} disabled={!deviceDetail.online || !canRefreshValues}>
            Refresh Values
          </Button>
          <Button variant="contained" onClick={handleReboot} disabled={!deviceDetail.online}>
            Reboot Device
          </Button>
          <Button variant="contained" onClick={handleLocationTrail}>
            {deviceDetail.locationTrailEnabled ? "Disable" : "Enable"} Location Tracking
          </Button>
          <Button
            variant="contained"
            onClick={launchMissionControl}
            hidden={
              !deviceDetail.online ||
              !isMegaSoftware(deviceDetail.eiSoftwareVersion) ||
              !(compareSoftwareVersions(deviceDetail.eiSoftwareVersion, RM_MEGAFI_MIN_UPDATE_VERSION) >= 0)
            }
          >
            LAUNCH MISSION CONTROL
          </Button>
        </Box>
      ) : null}
      <Confirmation />
    </PageSection>
  );
};

DetailActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deviceDetail: PropTypes.object.isRequired,
  mutatePendingActions: PropTypes.func,
  launchMissionControl: PropTypes.func.isRequired,
  mutateDetail: PropTypes.func,
};
DetailActions.defaultProps = {
  mutatePendingActions: undefined,
  mutateDetail: undefined,
};

export default DetailActions;
