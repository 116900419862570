import styled from "@emotion/styled";

export const PageSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 20px;
  position: relative;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  padding-top: 16px;
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
`;
