import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { CircularProgress, Typography, useTheme } from "@mui/material";

import LoadingSpinner from "components/LoadingSpinner";
import { PageSection, DetailWrapper, ActionBar } from "components/common/Wrapper";
import CustomTable from "components/table/table.component";
import { TableBodyCell } from "components/table/table.style";
import { FlatButton } from "components/common/Buttons";
import { useRequest } from "hooks/useRequest";
import ErrorScreen from "components/common/ErrorScreen";
import { convertIpAddress } from "utils/util-functions";
import DetailGridComponent from "./DetailGrid.component";
import DetailForm from "./DeviceDetailForm.component";
import { GridActionRow, SectionHeader, SpinnerWrapper } from "./DeviceDetail.style";
import PENDING_GRID_COLUMNS from "./DeviceDetail.config";
import DetailActions from "./DetailActions";
import { Popup } from "../../../components/common/popup/popup.component";
import { updateVpnDeviceStatus } from "../../../api/DeviceHelpers";
import { showSuccess, showWarning } from "../../../utils/notifications";

function DeviceDetail() {
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingEiInterfaces, setIsLoadingEiInterfaces] = useState(false);
  const [isControlLaunching, setIsControlLaunching] = useState(false);
  const [isVpnInitialized, setIsVpnInitialized] = useState(false);
  const [intervalId, setIntervalId] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [count, setCount] = useState(0);
  const VPN_CONNECTED = "Connected";
  const VPN_NOT_CONNECTED = "Not Connected";
  const VPN_CONNECTION_FAILED = "Connection Failed";
  const VPN_ERROR = "Error";
  const { sn: serialNumber } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: detailData,
    isLoading: isGettingDetail,
    mutate: mutateDetail,
    error: detailError,
  } = useRequest(`eiStatusUIHook?serialNumber=${serialNumber}`);

  // Initially load vpn data and check if 'vpnStatus' is 'Connected'.
  const {
    data: vpnData,
    mutate: mutateVpnHook,
    error: vpnError,
  } = useRequest(`vpnUIHook?serialNumber=${serialNumber}`);

  console.warn("VPNDATA", vpnData);

  const vpnConnectionOnClose = async () => {
    clearInterval(intervalId);
    setIsControlLaunching(false);
    setCount(0);
  };

  const errorPopupOnClose = async () => {
    clearInterval(intervalId);

    setIsError(false);
    setCount(0);
  };

  if (isControlLaunching && vpnData.vpnStatus === VPN_CONNECTED) {
    window.open(vpnData.mcUrl, "_blank", "noopener,noreferrer");
    console.warn("vpnConnectionOnClose", vpnConnectionOnClose);
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
    clearInterval(intervalId);
    setCount(0);
  }

  if (
    isControlLaunching &&
    isVpnInitialized &&
    (vpnData.vpnStatus === VPN_CONNECTION_FAILED || vpnData.vpnStatus === VPN_ERROR)
  ) {
    if (vpnData.vpnStatus === VPN_CONNECTION_FAILED) {
      showWarning("VPN connection could not be established. Please try later or contact support.");
    } else if (vpnData.vpnStatus === VPN_ERROR) {
      showWarning(
        "VPN connection could not be established. Please check for errors in the device system log or contact support.",
      );
    }
    console.warn("vpnConnectionOnClose", vpnConnectionOnClose);
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
    clearInterval(intervalId);
    setCount(0);
  }

  const deviceDetail = useMemo(() => {
    if (detailData && detailData?.deviceData) {
      return {
        ...detailData.deviceData[0],
        lanIpAddress: convertIpAddress(detailData.deviceData[0].lanIpAddress || ""),
      };
    }
    return null;
  }, [detailData]);

  const {
    data: pendingActions,
    isLoading: isLoadingActions,
    mutate: mutatePendingActions,
    error: pendingActionError,
  } = useRequest(`pendingActionsUIHook?serialNumber=${serialNumber}`);

  const pendingActionsData = useMemo(
    () =>
      pendingActions?.map((item) => ({
        ...item,
        completed: item.completed ? "Yes" : "No",
      })),
    [pendingActions],
  );

  const goToLocation = () => {
    navigate(`/map/${serialNumber}`);
  };

  const errorMessage = useMemo(() => {
    let message = "";

    if (!isLoadingActions && pendingActionError) {
      const { error: pendingErrorText } = pendingActionError.data;
      message += `${pendingErrorText}\n`;
    }

    if (!isGettingDetail && detailError) {
      const { error: detailErrorText } = detailError.data;
      message += detailErrorText;
    }

    return message ? `Errors occurred while calling the API:\n${message}` : null;
  }, [isLoadingActions, pendingActionError, isGettingDetail, detailError]);

  if (errorMessage) {
    return <ErrorScreen text={errorMessage} />;
  }

  const launchMissionControl = async () => {
    // If respoonse is 'Not Connected' call 'VPN Initialize API'.

    console.warn("isluanchmissioncontrol");

    try {
      setIsControlLaunching(true);

      if ((vpnData.vpnStatus === VPN_NOT_CONNECTED || vpnData.vpnStatus === VPN_ERROR) && !isVpnInitialized) {
        console.warn("isVpn NOT CONNECTED");
        const updateVpn = await updateVpnDeviceStatus(serialNumber);
        console.warn("updateVpn", updateVpn);
        // const vpnApiCheck = await start(serialNumber); -- PT Commented
        // console.warn("vpncheck", vpnApiCheck);         -- PT Commented
        // mutateVpnHook();
        setIsVpnInitialized(true);
      }

      if (vpnData.vpnStatus === VPN_CONNECTED) {
        console.warn("vpnConnectionOnClose", vpnConnectionOnClose);
        setIsControlLaunching(false);
        setIsVpnInitialized(false);
        clearInterval(intervalId);
        window.open(vpnData.mcUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.warn("vpnerror", error);
      setIsControlLaunching(false);
      setIsVpnInitialized(false);
      setIsError(true);
      setErrorText(error.message);
      clearInterval(intervalId);
    }
  };

  return (
    <DetailWrapper>
      <ActionBar style={{ marginBottom: 0 }}>
        <FlatButton onClick={() => goToLocation()}>
          <LocationOnIcon />
          Show Location
        </FlatButton>
      </ActionBar>

      <PageSection>
        <DetailForm
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail || isGettingDetail}
          mutateDetail={mutateDetail}
        />
      </PageSection>

      <GridActionRow>
        <PageSection>
          <DetailGridComponent
            isLoadingDetail={isLoadingEiInterfaces || isGettingDetail}
            deviceDetail={deviceDetail}
            setIsLoadingDetail={setIsLoadingEiInterfaces}
            mutateDetail={mutateDetail}
          />

          {isGettingDetail && (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          )}
        </PageSection>

        <DetailActions
          launchMissionControl={launchMissionControl}
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          mutateDetail={mutateDetail}
        />
      </GridActionRow>

      <PageSection>
        <SectionHeader>
          <span className="header-title">Pending Updates</span>
        </SectionHeader>

        <CustomTable>
          <CustomTable.Header columns={PENDING_GRID_COLUMNS} />

          <CustomTable.Body isLoading={isLoadingActions} deviceData={pendingActionsData}>
            {pendingActionsData?.map((row) => (
              <TableRow key={row.id}>
                {PENDING_GRID_COLUMNS.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableBodyCell key={column.id} align={column.align} style={{ color: theme.palette.gray[500] }}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      {isControlLaunching && (
        <Popup
          title="Establishing VPN connection...Please wait."
          isOpen={isControlLaunching}
          onClick={vpnConnectionOnClose}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "32px" }}>
            <CircularProgress size={60} variant="indeterminate" />
          </div>
          <Typography>Note: This process could take up to a minute or more.</Typography>
        </Popup>
      )}

      {isError && (
        <Popup title="Error" isOpen={isError} onClick={errorPopupOnClose}>
          <Typography>{errorText}</Typography>
        </Popup>
      )}
    </DetailWrapper>
  );
}

export default DeviceDetail;
