import axios from "axios";
import { getCurrentIdToken, isSessionValid, signOut } from "pages/Auth/Cogcognito";
import { showError } from "utils/notifications";

const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInst.interceptors.request.use((config) => {
  const requestConfig = { ...config };
  requestConfig.headers.Authorization = getCurrentIdToken();
  return requestConfig;
});

axiosInst.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("interceptor:", error.response);
    const { status, data } = error.response;

    if (!isSessionValid()) {
      showError("Invalid session please sign in again!");
      signOut();
      window.location.href = "/login";
    } else if (status === 401 || data?.message === "Unauthorized") {
      showError("Unauthorized!");
      signOut();
      window.location.href = "/login";
    } else {
      const { error: errorMsg, message } = error.response?.data || {};
      showError(errorMsg || message);
    }

    localStorage.setItem("apiFailCount", localStorage.getItem("apiFailCount") + 1);

    return Promise.reject(error.response);
  },
);

export default axiosInst;
