import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import ComputerIcon from "@mui/icons-material/Computer";
import TreeItem from "@mui/lab/TreeItem";
import { isEqual } from "lodash";

import { useRequestInfinite } from "hooks/useRequest";
import { DeviceTreeItem, LoadMoreButton, Spinner } from "./MapSidebar.style";
import { DEVICE_LIST_PAGE_SIZE, MISSING_DESC, KEY_FIELD_IDS } from "./MapSidebar.config";
import { DeviceItemLabel } from "./ItemLabel.component";

const DeviceList = ({
  poolId,
  onCheckDevice,
  checkedDeviceList,
  onDeviceLoaded,
  dataFieldStr,
  checkedPoolList,
  prevCheckedPoolList,
  keyField,
}) => {
  const { data, size, setSize, isLoading } = useRequestInfinite((index) => {
    const startIndex = DEVICE_LIST_PAGE_SIZE * index;
    const endIndex = startIndex + DEVICE_LIST_PAGE_SIZE - 1;
    return `/beta/devices?devicePool=${poolId}&firstDeviceIndex=${startIndex}&lastDeviceIndex=${endIndex}`;
  });

  const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty = !data?.[0]?.deviceData || data?.[0]?.deviceData?.length === 0;
  // TODO: This should be using totalNumber of Devices
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.deviceData?.length < DEVICE_LIST_PAGE_SIZE);

  const deviceArray = useMemo(() => {
    if (!data || !data.length) return [];

    let mergedArray = [];
    data.forEach(({ deviceData }) => {
      if (deviceData && deviceData.length) {
        mergedArray = mergedArray.concat(deviceData);
      }
    });

    mergedArray.sort((device1, device2) => {
      const value1 = device1[keyField] || "";
      const value2 = device2[keyField] || "";
      if (value1 > value2) {
        return 1;
      }
      if (value2 > value1) {
        return -1;
      }
      return 0;
    });

    return mergedArray.map(({ serialNumber, ...rest }) => ({
      serialNumber,
      checked: !!(checkedDeviceList ?? []).includes(serialNumber),
      ...rest,
    }));
  }, [data, keyField, checkedDeviceList]);

  useEffect(() => {
    if (isEqual(checkedPoolList, prevCheckedPoolList)) {
      return;
    }

    if (deviceArray?.length > 0) {
      deviceArray.forEach(({ serialNumber }) => {
        onCheckDevice(checkedPoolList.includes(poolId), serialNumber);
      });
    }
  }, [checkedPoolList, prevCheckedPoolList]);

  return (
    <>
      {deviceArray.map((device) => {
        const { serialNumber, checked } = device;
        const title = device[keyField];
        return (
          <DeviceTreeItem
            key={serialNumber}
            label={
              <DeviceItemLabel
                icon={<ComputerIcon />}
                title={title}
                serialNumber={serialNumber}
                description={MISSING_DESC[keyField]}
                onCheck={(e) => onCheckDevice(e.target.checked, serialNumber)}
                isChecked={checked}
                onDeviceLoaded={onDeviceLoaded}
                dataFieldStr={dataFieldStr}
              />
            }
            nodeId={`device-${serialNumber}`}
          />
        );
      })}

      {isLoadingMore ? (
        <TreeItem nodeId={`loading-${Math.random().toString(36).slice(2)}`} label={<Spinner />} />
      ) : isReachingEnd ? (
        <div />
      ) : (
        <TreeItem
          nodeId={`load-more-${Math.random().toString(36).slice(2)}`}
          label={
            <LoadMoreButton variant="outlined" size="small" onClick={() => setSize(size + 1)}>
              Load More
            </LoadMoreButton>
          }
        />
      )}
    </>
  );
};

DeviceList.propTypes = {
  poolId: PropTypes.string.isRequired,
  onCheckDevice: PropTypes.func.isRequired,
  checkedDeviceList: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkedPoolList: PropTypes.arrayOf(PropTypes.string).isRequired,
  prevCheckedPoolList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDeviceLoaded: PropTypes.func.isRequired,
  dataFieldStr: PropTypes.string.isRequired,
  keyField: PropTypes.string,
};

DeviceList.defaultProps = {
  keyField: KEY_FIELD_IDS.SERIAL_NO,
};

export default DeviceList;
