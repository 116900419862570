import React from "react";
import { Route } from "react-router-dom";

import DeviceDetail from "pages/Devices/Detail";
import MapPage from "pages/Map/MapPage.component";
import PoolsPage from "pages/Pools";
import PoolDetail from "pages/Pools/Detail";
import Users from "pages/Users";
import Devices from "pages/Devices";
import { useAuth } from "hooks/useProvideAuth";
import PrivateRoute from "./components/PrivateRoute";

const AdminWebUserRoute = () => {
  const { isAdmin } = useAuth();

  return (
    <>
      <Route
        path="/devices"
        element={
          <PrivateRoute>
            <Devices />
          </PrivateRoute>
        }
      />
      <Route
        path="/devices/:sn"
        element={
          <PrivateRoute>
            <DeviceDetail />
          </PrivateRoute>
        }
      />

      {isAdmin && (
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
      )}

      <Route
        path="/pools"
        element={
          <PrivateRoute>
            <PoolsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/pools/:poolId"
        element={
          <PrivateRoute>
            <PoolDetail />
          </PrivateRoute>
        }
      />

      <Route path="/map">
        <Route
          path=":sn"
          element={
            <PrivateRoute>
              <MapPage />
            </PrivateRoute>
          }
        />
        <Route
          path=""
          element={
            <PrivateRoute>
              <MapPage />
            </PrivateRoute>
          }
        />
      </Route>
    </>
  );
};
export default AdminWebUserRoute;
