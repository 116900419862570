import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Box, useTheme } from "@mui/material";

import updateEISoftwareHelper from "api/PendingActionsHelpers";
import { changeDeviceStatusFromUI } from "api/DeviceHelpers";
import { FlatButton } from "components/common/Buttons";
import { showWarning, showSuccess } from "utils/notifications";
import LoadingSpinner from "components/LoadingSpinner";
import useConfirm from "hooks/useConfirm";
import { FormInput } from "components/common/Input";
import useForm from "components/form/useForm";
import { useRequest } from "hooks/useRequest";
import { getDateString, nameValidationCheck } from "utils/util-functions";
import { compareSoftwareVersions, isMegaSoftware } from "utils/general";
import Select from "components/common/Select";
import { SpinnerWrapper, DetailRow, StatusDot, SectionHeader, DetailGridWrapper } from "./DeviceDetail.style";

const EI_MIN_UPDATE_VERSION = "EI-2.3.58";

const FormContent = ({
  deviceDetail,
  mutatePendingActions,
  setIsLoadingDetail,
  isEditing,
  setIsEditing,
  isLoadingDetail,
  mutateDetail,
}) => {
  const [formData, setFormData] = useState(deviceDetail);

  const { data: poolList } = useRequest("devicePools");

  const [getConfirmation, Confirmation] = useConfirm();
  const theme = useTheme();

  const { sn: serialNumber } = useParams();

  const availableVersions = useMemo(() => {
    if (!formData?.availableSW || !formData?.availableSW?.length) {
      return [];
    }
    const versions = [...formData.availableSW].sort(compareSoftwareVersions);

    return versions.map((version) => ({
      value: version,
      label: version,
    }));
  }, [formData]);

  const poolOptions = useMemo(() => {
    if (!poolList || !poolList.length) return [];
    return poolList.map(({ devicePool }) => ({ value: devicePool, label: devicePool }));
  }, [poolList]);

  const uptimeText = useMemo(() => {
    if (!formData.uptime) return "";
    const { days, hours, minutes, seconds } = formData.uptime;
    return [days && `${days}day`, hours && `${hours}h`, minutes && `${minutes}m`, seconds && `${seconds}s`]
      .filter((item) => !!item)
      .join(" ");
  }, [formData.uptime]);

  const saveFormChanges = useCallback(
    async (data) => {
      const {
        apn: newApn,
        deviceAlias: newDeviceAlias,
        deviceDescription: newDeviceDescription,
        eiSoftwareVersion: newEiSoftwareVersion,
        devicePool: newDevicePool,
      } = data;

      const newDeviceData = {};

      // check changed device data
      if (newApn !== formData.apn) newDeviceData.apn = newApn;
      if (newDeviceAlias !== formData.deviceAlias) newDeviceData.deviceAlias = newDeviceAlias;
      if (newDevicePool !== formData.devicePool) newDeviceData.devicePool = newDevicePool;
      if (newDeviceDescription !== formData.deviceDescription) newDeviceData.deviceDescription = newDeviceDescription;

      // if nothing changed
      if (!Object.keys(newDeviceData).length && formData.eiSoftwareVersion === newEiSoftwareVersion) {
        showWarning("Nothing is changed on the form!");
        return null;
      }

      // init form reset data
      let formResetData = {
        ...formData,
      };
      setIsLoadingDetail?.(true);

      if (formData.eiSoftwareVersion !== newEiSoftwareVersion) {
        try {
          const versionResponse = await updateEISoftwareHelper({
            serialNumber: formData.eiSerialNumber,
            softwareVersion: newEiSoftwareVersion,
          });

          const { data: responseData } = versionResponse || {};
          if (responseData) {
            showWarning(
              "Software update in progress. Do not power off your device. Check pending actions below to see when the software update is complete!",
            );
            mutatePendingActions?.();

            // this should be done from API response
            formResetData = { ...formResetData, eiSoftwareVersion: newEiSoftwareVersion };
          }
        } catch (error) {
          // if version API is failed
          setIsLoadingDetail?.(false);
          return formResetData;
        }
      }

      if (Object.keys(newDeviceData).length > 0) {
        try {
          const deviceResponse = await changeDeviceStatusFromUI({
            eiSerialNumber: formData.eiSerialNumber,
            ...newDeviceData,
          });

          const { data: responseData } = deviceResponse;
          if (responseData) {
            setIsEditing(false);
            setIsLoadingDetail(false);
            setFormData({
              ...formData,
              ...responseData,
            });

            // refetch device detail
            mutateDetail?.();
            showSuccess("Successfully updated device details!");

            return {
              ...formResetData,
              ...data,
            };
          }
        } catch (error) {
          // if Save fails, then revert all changes on the form
          // Error messages will be handled on Axios interceptor
          setIsLoadingDetail(false);
          return formResetData;
        }
      }

      setIsEditing(false);
      setIsLoadingDetail(false);
      return formResetData;
    },
    [formData, getConfirmation, setIsEditing, setIsLoadingDetail, mutatePendingActions, mutateDetail],
  );

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const { register, control, errors, Form } = useForm({
    defaultValues: {
      eiSoftwareVersion: formData.eiSoftwareVersion,
      deviceAlias: formData.deviceAlias,
      deviceDescription: formData.deviceDescription,
      apn: formData.apn,
      devicePool: formData?.devicePool,
    },
    isEditing,
    onSave: saveFormChanges,
    onCancel,
  });

  return (
    <Form isLoading={isEditing && isLoadingDetail}>
      <DetailGridWrapper>
        <DetailRow>
          <span className="label">Serial No.:</span>
          <span className="results">{serialNumber}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">Device Alias:</span>
          {isEditing ? (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <FormInput
                {...register("deviceAlias", {
                  validate: {
                    nameValidationCheck,
                  },
                })}
              />
              {errors.deviceAlias && <span className="error-text">{errors.deviceAlias.message}</span>}
            </Box>
          ) : (
            <span className="results">{formData.deviceAlias}</span>
          )}
        </DetailRow>
        <DetailRow>
          <span className="label">Software Version:</span>
          {isEditing ? (
            <Select
              name="eiSoftwareVersion"
              options={availableVersions}
              control={control}
              disabled={
                !formData.online &&
                (isMegaSoftware(deviceDetail.eiSoftwareVersion) ||
                  compareSoftwareVersions(deviceDetail.eiSoftwareVersion, EI_MIN_UPDATE_VERSION) > 0)
              }
            />
          ) : (
            <span className="results">{formData.eiSoftwareVersion}</span>
          )}
        </DetailRow>
        <DetailRow>
          <span className="label">IMEI:</span>
          <span className="results">{formData.imei}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">Last Checkin:</span>
          <span className="results">{getDateString(formData.lastCheckIn)}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">Device Pool:</span>
          {isEditing ? (
            <Select name="devicePool" options={poolOptions} control={control} />
          ) : (
            <span className="results">{formData.devicePool}</span>
          )}
        </DetailRow>
        <DetailRow>
          <span className="label">Device Description:</span>
          {isEditing ? (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <FormInput
                {...register("deviceDescription", {
                  validate: {
                    nameValidationCheck,
                  },
                })}
              />
              {errors.deviceDescription && <span className="error-text">{errors.deviceDescription.message}</span>}
            </Box>
          ) : (
            <span className="results">{formData.deviceDescription}</span>
          )}
        </DetailRow>
        <DetailRow>
          <span className="label">Current Status:</span>
          <div className="status-row">
            <StatusDot
              style={{
                backgroundColor: formData.online ? theme.palette.success.main : theme.palette.error.main,
                marginRight: 5,
              }}
            />
            <span className="results">{formData.online ? "ONLINE" : "OFFLINE"}</span>
          </div>
        </DetailRow>
        <DetailRow>
          <span className="label">Uptime:</span>
          <span className="results">{uptimeText}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">APN:</span>
          <span className="results">{formData.apn}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">SIM Phone Number:</span>
          <span className="results">{formData.phoneNumber}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">WWAN IP Address:</span>
          <span className="results">{formData.wwanIpAddress}</span>
        </DetailRow>
        <DetailRow>
          <span className="label">LAN IP Address:</span>
          <span className="results">{formData.lanIpAddress}</span>
        </DetailRow>

        <DetailRow className="start-align">
          <span className="label">TX Power:</span>
          <span className="results">{formData.txPower}</span>
        </DetailRow>

        <DetailRow className="start-align">
          <span className="label">RX Power:</span>
          <span className="results">{formData.RxPower}</span>
        </DetailRow>
      </DetailGridWrapper>

      <Confirmation />
    </Form>
  );
};
FormContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deviceDetail: PropTypes.object,
  mutatePendingActions: PropTypes.func,
  setIsLoadingDetail: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isLoadingDetail: PropTypes.bool.isRequired,
  mutateDetail: PropTypes.func,
};
FormContent.defaultProps = {
  deviceDetail: null,
  mutatePendingActions: undefined,
  mutateDetail: undefined,
};

const DetailForm = ({ deviceDetail, mutatePendingActions, setIsLoadingDetail, isLoadingDetail, mutateDetail }) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();

  return (
    <>
      <SectionHeader>
        <span className="header-title">Device Details</span>

        {!isLoadingDetail && !isEditing && (
          <FlatButton color="secondary" onClick={() => setIsEditing(true)}>
            <EditIcon style={{ color: theme.palette.gray[500] }} />
          </FlatButton>
        )}
      </SectionHeader>

      {deviceDetail && (
        <FormContent
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          mutateDetail={mutateDetail}
        />
      )}

      {!isEditing && isLoadingDetail && (
        <SpinnerWrapper>
          <LoadingSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
};
DetailForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deviceDetail: PropTypes.object,
  mutatePendingActions: PropTypes.func,
  setIsLoadingDetail: PropTypes.func.isRequired,
  isLoadingDetail: PropTypes.bool.isRequired,
  mutateDetail: PropTypes.func,
};
DetailForm.defaultProps = {
  deviceDetail: null,
  mutatePendingActions: undefined,
  mutateDetail: undefined,
};

export default DetailForm;
