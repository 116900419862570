import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { Select, MenuItem, Checkbox, Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { changeDeviceStatusFromUI } from "api/DeviceHelpers";
import { PrimaryButton, FlatButton, SecondButton } from "components/common/Buttons";
import { PageFooter } from "components/common/Wrapper";
import CustomDataGrid from "components/common/DataGrid";
import { SectionHeader } from "./DeviceDetail.style";

const TypeEditInputCell = ({ id, field, row, value, api }) => {
  const handleChange = (event) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
  };

  return (
    <Select value={value} onChange={handleChange}>
      {row.availableTypes.map((type) => (
        <MenuItem value={type}>{type}</MenuItem>
      ))}
    </Select>
  );
};

TypeEditInputCell.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  api: PropTypes.object.isRequired,
};

const EnabledEditInputCell = ({ id, field, value, api }) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    api.setEditCellValue({ id, field, value: event.target.checked }, event);
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};
EnabledEditInputCell.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  api: PropTypes.object.isRequired,
};

const renderTypeEditCell = (params) => <TypeEditInputCell {...params} />;

const renderEnabledEditCell = (params) => <EnabledEditInputCell {...params} />;

const DetailGridComponent = ({ isLoadingDetail, deviceDetail, setIsLoadingDetail, mutateDetail }) => {
  const [isEditingInterfaces, setIsEditingInterfaces] = useState(false);
  const [gridRows, setGridRows] = useState(deviceDetail?.eiInterfaces || []);
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        field: "type",
        headerName: "Type",
        editable: isEditingInterfaces,
        renderEditCell: renderTypeEditCell,
        type: "string",
      },
      { field: "port", headerName: "Port" },
      { field: "rxBytes", headerName: "Received Bytes" },
      { field: "txBytes", headerName: "Transmitted Bytes", width: 150 },
      {
        field: "isEnabled",
        headerName: "Enabled",
        editable: isEditingInterfaces,
        renderEditCell: renderEnabledEditCell,
        type: "boolean",
      },
    ],
    [isEditingInterfaces],
  );

  useEffect(() => {
    setGridRows(deviceDetail?.eiInterfaces || []);
  }, [deviceDetail]);

  const handleEditRowsModelChange = (model) => {
    const editedIds = Object.keys(model);

    if (editedIds.length > 0) {
      const index = editedIds[0];

      // convert model[index]
      const modelValue = {};
      Object.keys(model[index]).forEach((key) => {
        const itemData = model[index][key];
        modelValue[key] = itemData.value;
      });

      const rows = [...gridRows];
      rows[index] = { ...rows[index], ...modelValue };
      setGridRows(rows);
    }
  };

  const onCancelEdit = () => {
    setIsEditingInterfaces(false);
    setGridRows(deviceDetail?.eiInterfaces || []);
  };

  const onSaveChange = async () => {
    setIsLoadingDetail(true);

    try {
      const deviceResponse = await changeDeviceStatusFromUI({
        eiSerialNumber: deviceDetail.eiSerialNumber,
        eiInterfaces: gridRows,
      });

      const { data } = deviceResponse;
      if (data) {
        setIsEditingInterfaces(false);
        setGridRows(data?.eiInterfaces || []);

        // refetch grid data
        mutateDetail?.();
      }
    } catch (error) {
      // error handling
    } finally {
      setIsLoadingDetail(false);
    }
  };

  const noRowsOverlay = () => (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No records found!
    </Stack>
  );

  return (
    <>
      <SectionHeader>
        <span className="header-title">EI Interfaces</span>

        {!isEditingInterfaces && !isLoadingDetail && (
          <FlatButton color="secondary" onClick={() => setIsEditingInterfaces(true)}>
            <EditIcon style={{ color: theme.palette.gray[500] }} />
          </FlatButton>
        )}
      </SectionHeader>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ width: "100%" }}>
          <CustomDataGrid
            hideFooter
            getRowId={(row) => row.port}
            rows={gridRows}
            columns={columns}
            onEditRowsModelChange={handleEditRowsModelChange}
            components={{
              NoRowsOverlay: noRowsOverlay,
            }}
          />
        </Box>

        {isEditingInterfaces && (
          <PageFooter>
            <PrimaryButton loading={isLoadingDetail} onClick={onSaveChange}>
              Save
            </PrimaryButton>
            <SecondButton onClick={onCancelEdit} sx={{ ml: 2 }}>
              Cancel
            </SecondButton>
          </PageFooter>
        )}
      </Box>
    </>
  );
};
DetailGridComponent.propTypes = {
  isLoadingDetail: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deviceDetail: PropTypes.object,
  setIsLoadingDetail: PropTypes.func.isRequired,
  mutateDetail: PropTypes.func,
};
DetailGridComponent.defaultProps = {
  deviceDetail: null,
  mutateDetail: undefined,
};

export default DetailGridComponent;
