import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  Region: process.env.REACT_APP_AWS_REGION,
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID,
});

export const createUser = (email, password, name, phoneNumber, organization, callback) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: "name",
      Value: name,
    }),
    new CognitoUserAttribute({
      Name: "phone_number",
      Value: phoneNumber,
    }),
    new CognitoUserAttribute({
      Name: "preferred_username",
      Value: organization,
    }),
  ];

  // Username must be unique in a pool, and cant be a valid email format
  // To log in with email, make sure it is set as an alias attribute in Cognito
  // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames

  userPool.signUp(email, password, attributeList, null, callback);
};

export const verifyUser = (email, verifyCode, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(verifyCode, true, callback);
};

export const resendVerifyCode = (email, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode(callback);
};

export const authenticateUser = (email, password, authCallback, mfaCallback, newPasswordCallback) => {
  const authData = {
    Username: email,
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authDetails, {
    onSuccess: (result) => {
      authCallback(null, result);
    },
    onFailure: (err) => {
      authCallback(err.message, null);
    },
    mfaRequired: function () {
      mfaCallback?.(cognitoUser, this);
    },
    newPasswordRequired: function (userAttributes) {
      delete userAttributes.email; // eslint-disable-line
      delete userAttributes.phone_number; // eslint-disable-line
      delete userAttributes.email_verified; // eslint-disable-line
      delete userAttributes.phone_number_verified; // eslint-disable-line
      newPasswordCallback(cognitoUser, userAttributes, this);
    },
  });
};

export const signOut = () => {
  userPool.getCurrentUser().signOut();
  localStorage.setItem("id", "");
  localStorage.setItem("access", "");
};

export const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("Cannot fetch valid Cognitor User"));
    } else {
      cognitoUser.getSession((sessionErr) => {
        if (sessionErr) {
          console.log(sessionErr);
          reject(sessionErr);
        } else {
          cognitoUser.getUserData((err, result) => {
            if (err) {
              console.log(err);
              reject(err);
            } else {
              const { UserAttributes, UserMFASettingList } = result;
              UserAttributes.push({
                Name: "isMFAEnabled",
                Value: !!UserMFASettingList?.length,
              });
              resolve(UserAttributes);
            }
          });
        }
      });
    }
  });

export const getCurrentIdToken = () => {
  let idToken = null;
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) return null;

  cognitoUser.getSession((err, session) => {
    const {
      idToken: { jwtToken },
    } = session;

    if (err) {
      console.log(err);
      return;
    }
    // console.log("session: " + JSON.stringify(jwtToken));
    idToken = jwtToken;
  });
  // console.log("idToken: " + JSON.stringify(idToken));
  return idToken;
};

export const isSessionValid = () => {
  let isCurrentSessionValid = false;
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) return false;

  cognitoUser.getSession((err, session) => {
    if (err) {
      console.log(err);
      return;
    }

    const {
      idToken: { payload },
    } = session;

    // if token is expired
    if (Date.now() >= payload.exp * 1000) {
      signOut();
      return;
    }
    isCurrentSessionValid = session.isValid();
  });
  return isCurrentSessionValid;
};

export const forgotPassword = (email, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: (result) => {
      callback(null, result);
    },
    onFailure: (err) => {
      callback(err, null);
    },
  });
};

export const forgotPasswordSubmit = (email, code, password, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(code, password, {
    onSuccess: (result) => {
      callback(null, result);
    },
    onFailure: (err) => {
      callback(err, null);
    },
  });
};
