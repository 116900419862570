import { useEffect, useState, useContext } from "react";

import { isSessionValid, getCurrentUser, signOut as logOut } from "pages/Auth/Cogcognito";
import { convertCognitoAttr } from "utils/util-functions";
import { GlobalContext } from "context/GlobalContext";
import { useNavigate } from "react-router";

const useProvideAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(isSessionValid());
  const [userData, setUserData] = useState({});
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const navigate = useNavigate();

  const updateInfo = () => {
    const isAuth = isSessionValid();
    setIsAuthenticated(isAuth);

    return new Promise((resolve, reject) => {
      if (isAuth) {
        try {
          getCurrentUser().then((user) => {
            if (user) {
              const userAttr = convertCognitoAttr(user);
              setUserData(userAttr);
              resolve(userAttr);
            } else {
              reject(new Error("Invalid User!"));
            }
          });
        } catch (error) {
          // error handling
          reject(error);
        }
      } else {
        reject(new Error("User is not authenticated!"));
      }
    });
  };

  const signOut = () => {
    logOut?.();
    navigate("/login");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (isSessionValid()) {
      updateInfo().then(() => setIsAuthLoaded(true));
    }
  }, []);

  return {
    isAuthenticated,
    signOut,
    refetch: updateInfo,
    isAuthLoaded,
    ...userData,
  };
};

const useAuth = () => {
  const { auth } = useContext(GlobalContext);
  return auth;
};

export { useProvideAuth, useAuth };
