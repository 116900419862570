import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import styled from "@emotion/styled";

import { isSessionValid } from "pages/Auth/Cogcognito";
import Sidebar from "components/sidebar";
import Menubar from "components/menubar";
import BREAKPOINTS from "utils/breakpoints";

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const ContentWrapper = styled.div`
  padding: 30px 20px 20px 20px;
  overflow: auto;
  flex: 1;
  height: 100%;

  ${BREAKPOINTS.md} {
    margin-left: 0;
  }
`;

const MainContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
  // max-width: 1800px;
  margin: auto;
  overflow: hidden;
`;

const PrivateRoute = ({ children }) => {
  const isAuthenticated = isSessionValid();
  const [showDrawer, setShowDrawer] = useState(false);

  return isAuthenticated ? (
    <MainLayout>
      <Menubar toggleDrawer={() => setShowDrawer(!showDrawer)} />
      <MainContent>
        <Sidebar showDrawer={showDrawer} onCloseDrawer={() => setShowDrawer(false)} />
        <ContentWrapper>{children}</ContentWrapper>
      </MainContent>
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
