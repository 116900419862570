import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const NavButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: none;
  cursor: pointer;
  padding: 6px 21px;
  border-radius: 20px;

  a {
    font-weight: 500;
    font-family: var(--font-primary);
    font-size: 17px;
    text-decoration: none;
    margin: 0;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const FlatButton = styled(Button)`
  gap: 8px;
`;

export const styledFlatButton = {
  paddingLeft: 10,
  paddingRight: 10,
  fontSize: 11,
  textWrap: "nowrap",
};

const StyledTextButton = styled(Button)`
  padding: 0;
  text-transform: none;
  min-width: auto;
`;

export const TextButton = (props) => <StyledTextButton variant="text" {...props} />;

export const PrimaryButton = (props) => <Button variant="contained" color="primary" {...props} />;

export const ActionButton = (props) => (
  <LoadingButton
    variant="contained"
    style={{
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 11,
      textWrap: "nowrap",
    }}
    color="primary"
    {...props}
  />
);

export const SmallFlatButton = (props) => <Button variant="text" {...props} sx={styledFlatButton} />;

export const SecondButton = (props) => <LoadingButton variant="contained" color="secondary" {...props} />;

const StyledLoadingButton = styled(LoadingButton)`
  border-radius: 5px;
  font-size: 20px;
  width: 100%;
  font-family: var(--font-primary);
  padding-top: 3px;
  padding-bottom: 3px;
`;

export const AuthButton = (props) => <StyledLoadingButton variant="contained" color="primary" {...props} />;
