import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "../Buttons";

export function Popup({ isOpen, onClick, title, children }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "16px",
          }}
        >
          <PrimaryButton onClick={onClick}>Cancel</PrimaryButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

Popup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
