/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { FormInput, FieldError, PhoneInputWrapper } from "components/common/Input";
import Select from "components/common/Select";
import { emailRegex } from "utils/constants";
import { useForm, useFormState } from "react-hook-form";
import { nameValidationCheck } from "utils/util-functions";
import { PageFooter } from "components/common/Wrapper";
import { PrimaryButton, SecondButton } from "components/common/Buttons";
import { createNewUser } from "api/AdminHelpers";
import { showSuccess } from "utils/notifications";

const NewAdminDialog = ({ open, orgList, userId, selectedOrg, onClose, onAdd }) => {
  const [phoneNum, setPhoneNum] = useState();
  const [isCreatingNewAdmin, setIsCreatingNewAdmin] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  const formState = useFormState({ control });

  useEffect(() => {
    setValue("adminName", "");
    setValue("groupName", selectedOrg ?? "");
    setValue("email", "");
    setPhoneNum("");
  }, []);

  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const onCreateNewAdmin = async ({ adminName, email, groupName }) => {
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    setIsCreatingNewAdmin(true);
    try {
      const { data } = await createNewUser({
        username: userId,
        groupname: groupName,
        name: adminName,
        phonenumber: phoneNum,
        email: email,
        role: "admin",
      });
      showSuccess(data?.message);

      onAdd();
      onClose();
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingNewAdmin(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onModalClose} disableEscapeKeyDown>
      <DialogTitle>New Admin</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormInput
            {...register("adminName", {
              required: "Admin Name is required",
              validate: {
                nameValidationCheck,
              },
            })}
            placeholder="Admin Name"
            error={errors.adminName}
          />
          {errors.adminName && <FieldError>{errors.adminName.message}</FieldError>}

          {!selectedOrg && (
            <Select
              name="groupName"
              options={orgList}
              control={control}
              rules={{ required: "Organization is required" }}
              style={{ marginTop: 15 }}
              error={errors.groupName}
            />
          )}
          {errors.groupName && <FieldError>{errors.groupName.message}</FieldError>}

          <FormInput
            placeholder="Email"
            {...register("email", {
              required: "Email is required!",
              pattern: {
                value: emailRegex,
                message: "Entered value does not match email format!",
              },
            })}
            style={{ marginTop: 20 }}
            error={errors.email}
          />
          {errors.email && <FieldError>{errors.email.message}</FieldError>}

          <PhoneInputWrapper
            international
            defaultCountry="US"
            countryCallingCodeEditable={false}
            value={phoneNum}
            onChange={(e) => {
              setPhoneNum(e);
            }}
          />
          {formState?.submitCount > 0 && (
            <FieldError>
              {phoneNum
                ? isPossiblePhoneNumber(phoneNum)
                  ? undefined
                  : "Phone number is not valid!"
                : "Phone number is required!"}
            </FieldError>
          )}

          <PageFooter>
            <PrimaryButton onClick={handleSubmit(onCreateNewAdmin)} loading={isCreatingNewAdmin}>
              Create
            </PrimaryButton>
            <SecondButton onClick={onClose} sx={{ ml: 2 }}>
              Cancel
            </SecondButton>
          </PageFooter>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

NewAdminDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  orgList: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOrg: PropTypes.string,
  userId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

NewAdminDialog.defaultProps = {
  selectedOrg: "",
};

export default NewAdminDialog;
