import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";

import { PageHeader } from "components/common/Wrapper";
import { useRequestInfinite } from "hooks/useRequest";
import usePrevious from "hooks/usePrevious";
import {
  SidebarWrapper,
  DrawerWrapper,
  LoadMoreButton,
  DeviceTreeView,
  ContentWrapper,
  KeyFieldSelect,
  Spinner,
} from "./MapSidebar.style";
import DeviceList from "./DeviceList.component";
import { POOL_LIST_PAGE_SIZE, AVAILABLE_KEY_FIELDS, KEY_FIELD_IDS } from "./MapSidebar.config";
import { CheckableLabel, TreeIconItem } from "./ItemLabel.component";

const MapSidebarContent = ({ onDeviceChange, initialChecked, activeDevice, onDeviceLoaded, dataFieldStr }) => {
  const [checkedDeviceList, setCheckedDeviceList] = useState(initialChecked || []);
  const [checkedPoolList, setCheckedPoolList] = useState([]);
  const [keyField, setKeyField] = useState(KEY_FIELD_IDS.SERIAL_NO);
  const [poolArray, setPoolArray] = useState([]);
  const prevCheckedPoolList = usePrevious(checkedPoolList);

  const { data, size, setSize, isLoading } = useRequestInfinite((index) => {
    const startIndex = POOL_LIST_PAGE_SIZE * index;
    const endIndex = startIndex + POOL_LIST_PAGE_SIZE - 1;
    return `/beta/devicePools?firstDevicePoolIndex=${startIndex}&lastDevicePoolIndex=${endIndex}`;
  });

  const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  const isReachingEnd = useMemo(() => {
    if (!data || !data.length) return true;

    return data[0].totalDevicePoolsInOrg <= poolArray.length;
  }, [poolArray]);

  useEffect(() => {
    if (!data || !data.length) {
      setPoolArray([]);
    } else {
      let mergedArray = [];
      data.forEach(({ devicePoolData }) => {
        mergedArray = mergedArray.concat(devicePoolData || []);
      });

      setPoolArray(mergedArray);
    }
  }, [data]);

  const onCheckNode = (checked, nodeId, deviceCheck = true) => {
    if (deviceCheck) {
      setCheckedDeviceList((checkedList) => {
        const newList = checkedList.filter((item) => item !== nodeId);

        if (checked) {
          newList.push(nodeId);
        }

        onDeviceChange?.(newList);

        return newList;
      });
    } else {
      setCheckedPoolList((poolList) => {
        const newList = poolList.filter((item) => item !== nodeId);

        if (checked) {
          newList.push(nodeId);
        }

        return newList;
      });
    }
  };

  const onKeyFieldChange = (event) => {
    setKeyField(event.target.value);
  };

  const highlightDevices = useMemo(() => (activeDevice ? [`device-${activeDevice}`] : []), [activeDevice]);

  return (
    <ContentWrapper>
      <PageHeader>
        <span className="header-title">Device List</span>

        <FormControl sx={{ m: 0, width: 110 }} size="small">
          <InputLabel id="key-field-label" style={{ fontSize: 13 }}>
            Key Field
          </InputLabel>
          <KeyFieldSelect
            labelId="key-field-label"
            id="select-key-field"
            value={keyField}
            label="Key Field"
            onChange={onKeyFieldChange}
          >
            {AVAILABLE_KEY_FIELDS.map(({ id, label }) => (
              <MenuItem key={id} sx={{ fontSize: 13 }} value={id}>
                {label}
              </MenuItem>
            ))}
          </KeyFieldSelect>
        </FormControl>
      </PageHeader>

      <DeviceTreeView
        selected={highlightDevices}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {poolArray.map(({ devicePool }) => (
          <TreeIconItem
            className="pool-node"
            key={devicePool}
            nodeId={`pool-${devicePool}`}
            label={
              <CheckableLabel
                icon={<DevicesOtherIcon />}
                title={devicePool}
                onCheck={(e) => onCheckNode(e.target.checked, devicePool, false)}
                isChecked={checkedPoolList.includes(devicePool)}
              />
            }
          >
            <DeviceList
              poolId={devicePool}
              onCheckDevice={onCheckNode}
              checkedDeviceList={checkedDeviceList}
              onDeviceLoaded={onDeviceLoaded}
              dataFieldStr={dataFieldStr}
              checkedPoolList={checkedPoolList}
              prevCheckedPoolList={prevCheckedPoolList}
              keyField={keyField}
            />
          </TreeIconItem>
        ))}

        {isLoadingMore ? (
          <TreeItem nodeId={`loading-${Math.random().toString(36).slice(2)}`} label={<Spinner />} />
        ) : isReachingEnd ? (
          <div />
        ) : (
          <TreeItem
            nodeId={`load-more-${Math.random().toString(36).slice(2)}`}
            label={
              <LoadMoreButton variant="outlined" size="small" onClick={() => setSize(size + 1)}>
                Load More
              </LoadMoreButton>
            }
          />
        )}
      </DeviceTreeView>
    </ContentWrapper>
  );
};
MapSidebarContent.propTypes = {
  onDeviceChange: PropTypes.func.isRequired,
  initialChecked: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDevice: PropTypes.string,
  onDeviceLoaded: PropTypes.func.isRequired,
  dataFieldStr: PropTypes.string.isRequired,
};
MapSidebarContent.defaultProps = {
  activeDevice: null,
};

const MapSidebar = ({ showDrawer, onCloseDrawer, ...rest }) => (
  <div>
    <SidebarWrapper>
      <MapSidebarContent {...rest} />
    </SidebarWrapper>

    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={() => onCloseDrawer?.()}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <DrawerWrapper>{/* <MapSidebarContent {...rest} /> */}</DrawerWrapper>
    </Drawer>
  </div>
);
MapSidebar.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
};

export default MapSidebar;
