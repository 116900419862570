import React, { useEffect, useState, useMemo } from "react";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import { Menu, Button, MenuItem } from "@mui/material";

import { deleteDevice, addSingleDeviceAPIHelper, changeDeviceStatusFromUI } from "api/DeviceHelpers";
import { PageTitle } from "components/common/Text";
import { FlatButton, SecondButton, PrimaryButton } from "components/common/Buttons";
import { PageFooter, ActionBar } from "components/common/Wrapper";
import { FormInput, FieldError } from "components/common/Input";
import { showSuccess, showWarning } from "utils/notifications";
import CustomTable from "components/table";
import { getAllDevicePools } from "api/DevicePoolHelpers";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import { getFilteredListNew, getSortList } from "utils/util-functions";
import useConfirm from "hooks/useConfirm";
import Select from "components/common/Select";
import TableFilterNew from "components/table/TableFilterNew.component";
import DASHBOARD_COLUMNS from "./Devices.config";

const DevicesPage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isCreatingNewDevice, setIsCreatingNewDevice] = useState(false);
  const [isOpenNewDeviceModal, setIsOpenNewDeviceModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [poolList, setPoolList] = useState([]);
  const [filterColumn, setFilterColumn] = useState();
  const [filterWord, setFilterWord] = useState();
  const [filterType, setFilterType] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [updating, setUpdating] = useState(false);
  const [sortBy, setSortBy] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const open = Boolean(actionsAnchorEl);
  const handleActionsClick = (event) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const [getConfirmation, Confirmation] = useConfirm();

  const filterColumns = DASHBOARD_COLUMNS.filter(({ filter }) => filter);
  const dataColumns = useMemo(() => {
    const columns = [...DASHBOARD_COLUMNS];
    columns.splice(0, 1);
    return columns;
  }, []);

  const poolOptions = useMemo(
    () => poolList.map(({ devicePool }) => ({ value: devicePool, label: devicePool })),
    [poolList],
  );

  const startIndex = useMemo(() => rowsPerPage * page || 0, [rowsPerPage, page]);
  const endIndex = useMemo(() => startIndex + rowsPerPage - 1, [startIndex, rowsPerPage]);
  const { data, isLoading, mutate } = useRequest(
    `eiStatusUIHook?firstDeviceIndex=${startIndex}&lastDeviceIndex=${endIndex}`,
  );

  const totalDeviceCount = useMemo(() => data?.totalDeviceCount || 0, [data]);
  const totalPageCount = useMemo(() => Math.ceil(totalDeviceCount / rowsPerPage), [totalDeviceCount, rowsPerPage]);

  const deviceData = useMemo(() => {
    if (!data?.deviceData) return [];

    return data?.deviceData.map((value) => {
      const { eiInterfaces } = value;
      const wan = eiInterfaces.find((item) => item.type === "WAN");

      return {
        ...value,
        txBytes: wan?.txBytes?.toString() || "",
        rxBytes: wan?.rxBytes?.toString() || "",
      };
    });
  }, [data]);

  const filteredList = useMemo(
    () => getFilteredListNew(deviceData, filterColumn, filterType, filterWord),
    [deviceData, filterColumn, filterWord, filterType],
  );

  const sortedList = useMemo(() => {
    const sortColumn = DASHBOARD_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortOrder, sortBy, filteredList]);

  const fetchData = () => {
    setSelectedRows([]);
    mutate?.();
  };

  useEffect(() => {
    // load device pools
    getAllDevicePools().then(({ data: devicePools }) => {
      if (devicePools?.length > 0) {
        setPoolList(devicePools);
      }
    });
  }, []);

  const getRowSN = (row) => (row.eiSerialNumber === undefined ? "default" : row.eiSerialNumber);

  const handleEIRowClick = (serialNumber) => {
    if (serialNumber !== "default") {
      navigate(`/devices/${serialNumber}`);
    }
  };

  const onNewDevice = () => {
    setValue("serialNumber", "");
    setValue("macAddress", "");
    setIsOpenNewDeviceModal(true);
  };

  const onRemoveDevice = async () => {
    if (selectedRows && selectedRows.length > 0) {
      const isConfirm = await getConfirmation({
        title: "Delete Device",
        content: "Are you sure to delete selected devices?",
      });

      if (isConfirm) {
        if (selectedRows.length > 1) {
          showWarning("Cannot remove multiple devices for now, please select single device!");
        } else {
          try {
            await deleteDevice(selectedRows[0]);
            fetchData();
            showSuccess(`Successfully removed device: ${selectedRows[0]}`);
          } catch (error) {
            // error handling
          }
        }
      }
    }
  };

  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpenNewDeviceModal(false);
    }
  };

  const onAddNewDevice = async ({ macAddress, serialNumber, devicePool }) => {
    setIsCreatingNewDevice(true);

    try {
      const res = await addSingleDeviceAPIHelper({
        mac: macAddress,
        serialNumber,
        devicePool: devicePool || "default",
      });

      if (res.data) {
        showSuccess("Added new device successfully!");
        setIsOpenNewDeviceModal(false);
        // refresh table
        fetchData();
      }
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingNewDevice(false);
    }
  };

  const handleChange = (event, row) => {
    const { eiSerialNumber } = row;
    const newSelected = [...selectedRows];
    const index = selectedRows.indexOf(eiSerialNumber);

    if (event.target.checked) {
      if (index === -1) {
        newSelected.push(eiSerialNumber);
      }
    } else if (index !== -1) {
      newSelected.splice(index, 1);
    }

    setSelectedRows(newSelected);
  };

  const isSelectedRow = (eiSerialNumber) => selectedRows.indexOf(eiSerialNumber) !== -1;

  const handleRequestSort = (order, orderBy) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  const onSearch = (column, columnType, searchContent) => {
    console.log("---", column, columnType, searchContent);
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
  };

  const onResetFilter = () => {
    setFilterColumn();
    setFilterWord();
    setFilterType();
    setPage(0);
  };

  const onPrevSearch = () => {
    setPage((p) => p - 1);
  };

  const onNextSearch = () => {
    setPage((p) => p + 1);
  };

  const handleUpdateDevice = async (isRefresh) => {
    handleActionsClose();

    const isOnlineConfirmed = await getConfirmation({
      title: isRefresh ? "Refresh Values" : "Reboot Device",
      content: `This action will be applied to online devices only`,
    });

    if (!isOnlineConfirmed) {
      return;
    }

    onSearch("online", "option", true);

    const deviceList = getFilteredListNew(deviceData, "online", "option", true);
    const rows = selectedRows.filter((eiSerialNumber) => deviceList.find((x) => x.eiSerialNumber === eiSerialNumber));

    if (!rows.length) {
      onResetFilter();
      showWarning("There are no devices online.");
      return;
    }
    const isRefreshValues = await getConfirmation({
      title: isRefresh ? "Refresh Values" : "Reboot Device",
      content: isRefresh
        ? "Are you sure to refresh values?"
        : "Are you sure? You will lose connectivity while the device reboots.",
    });

    if (!isRefreshValues) {
      onResetFilter();
      return;
    }

    setUpdating(true);
    const requests = rows.map((r) =>
      changeDeviceStatusFromUI({
        eiSerialNumber: r,
        ...(isRefresh ? { getDeviceDetails: true } : { rebootDevice: true }),
      }),
    );
    await Promise.all(requests);
    onResetFilter();
    setUpdating(false);
  };

  const handleLocationTrail = async (isDisable) => {
    handleActionsClose();
    const isConfirmed = await getConfirmation({
      title: "Location Tracking",
      content: `Are you sure you want to ${isDisable ? "disable" : "enable"} location tracking`,
    });

    if (!isConfirmed) {
      return;
    }

    setUpdating(true);

    const formData = isDisable ? { disableLocationTrail: true } : { enableLocationTrail: true };

    const requests = selectedRows.map((r) =>
      changeDeviceStatusFromUI({
        eiSerialNumber: r,
        ...formData,
      }),
    );
    await Promise.all(requests);

    fetchData();
    setUpdating(false);
  };

  return (
    <>
      <PageTitle>Devices</PageTitle>

      <TableFilterNew
        columns={filterColumns}
        onSearch={onSearch}
        onReset={onResetFilter}
        showPrevButton={page > 0}
        onPrevSearch={onPrevSearch}
        showNextButton={page < totalPageCount - 1}
        onNextSearch={onNextSearch}
        resultCount={sortedList?.length}
      />

      <ActionBar style={{ justifyContent: "flex-end" }}>
        <Box display="flex" gap={2}>
          <FlatButton onClick={onNewDevice}>
            <AddCircleOutlineIcon />
            New Device
          </FlatButton>
          <FlatButton onClick={onRemoveDevice} disabled={selectedRows?.length <= 0}>
            <DeleteIcon />
            Remove Device
          </FlatButton>
          <Button
            id="device-actions-button"
            disabled={selectedRows?.length <= 0}
            aria-controls={open ? "device-actions-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleActionsClick}
          >
            Actions
          </Button>
          <Menu
            id="device-actions-menu"
            aria-labelledby="device-actions-button"
            anchorEl={actionsAnchorEl}
            open={open}
            onClose={handleActionsClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleLocationTrail(true)}>Disable Location Tracking</MenuItem>
            <MenuItem onClick={() => handleLocationTrail(false)}>Enable Location Tracking</MenuItem>
            <MenuItem onClick={() => handleUpdateDevice(true)}>Refresh Values</MenuItem>
            <MenuItem onClick={() => handleUpdateDevice(false)}>Reboot Device</MenuItem>
          </Menu>
        </Box>
      </ActionBar>

      <CustomTable
        rowsPerPageOptions={[10, 25]}
        totalCount={totalDeviceCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={DASHBOARD_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading || updating} deviceData={sortedList}>
          {!isLoading &&
            sortedList &&
            sortedList.map((row, index) => {
              const { eiSerialNumber } = row;
              const isItemSelected = isSelectedRow(eiSerialNumber);

              return (
                row && (
                  <TableRow hover role="checkbox" tabIndex={-1} key={eiSerialNumber} selected={isItemSelected}>
                    <TableBodyCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleChange(event, row)}
                      />
                    </TableBodyCell>
                    {dataColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableBodyCell
                          className={column.className}
                          key={column.id}
                          align={column.align}
                          onClick={() => handleEIRowClick(getRowSN(row), index)}
                        >
                          {column.format ? column.format(value) : value}
                        </TableBodyCell>
                      );
                    })}
                  </TableRow>
                )
              );
            })}
        </CustomTable.Body>
      </CustomTable>

      <Dialog fullWidth maxWidth="xs" open={isOpenNewDeviceModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>New Device</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormInput
              {...register("serialNumber", { required: "Serial Number is required", minLength: 11, maxLength: 12 })}
              placeholder="Serial Number"
              error={errors.serialNumber}
            />
            {errors.serialNumber && <FieldError>{errors.serialNumber.message}</FieldError>}
            {errors.serialNumber &&
              (errors.serialNumber.type === "maxLength" || errors.serialNumber.type === "minLength") && (
                <FieldError>Length of Serial Number should be 11 or 12</FieldError>
              )}

            <FormInput
              {...register("macAddress", { required: "MAC Address is required" })}
              placeholder="MAC Address"
              style={{ marginTop: 15 }}
              error={errors.macAddress}
            />
            {errors.macAddress && <FieldError>{errors.macAddress.message}</FieldError>}

            <Select
              name="devicePool"
              options={poolOptions}
              control={control}
              rules={{ required: "Device Pool is required" }}
              style={{ marginTop: 15 }}
              error={errors.devicePool}
            />
            {errors.devicePool && <FieldError>{errors.devicePool.message}</FieldError>}

            <PageFooter>
              <PrimaryButton onClick={handleSubmit(onAddNewDevice)} loading={isCreatingNewDevice}>
                Save
              </PrimaryButton>
              <SecondButton onClick={() => setIsOpenNewDeviceModal(false)} sx={{ ml: 2 }}>
                Cancel
              </SecondButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>

      <Confirmation />
    </>
  );
};

export default DevicesPage;
