import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

import ActionBarSelect from "components/action-bar-select/action-bar-select";
import { FormInput } from "components/common/Input";
import { ActionButton, SmallFlatButton } from "components/common/Buttons";
import { getDateString } from "utils/util-functions";
import { COLUMN_TYPE } from "utils/constants";
import { ActiveFilter, DateFilterWrapper, FilterWrapper } from "./table.style";

const TableFilterNew = ({
  columns,
  onSearch,
  onReset,
  onPrevSearch,
  onNextSearch,
  showPrevButton,
  showNextButton,
  resultCount,
}) => {
  const [activeFilter, setActiveFilter] = useState();
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const isValidFilter = useMemo(() => !!activeFilter, [activeFilter]);
  const columnOptions = useMemo(() => columns?.map(({ id, label }) => ({ value: id, label })) || [], [columns]);

  const onResetFilter = () => {
    setActiveFilter(null);
    onReset?.();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const columnField = watch("column", "");

  const selectedColumn = useMemo(() => {
    const selected = columns.find(({ id }) => id === columnField);
    return selected || columns?.[0];
  }, [columnField]);

  const onSearchClick = ({ option, searchWord: word }) => {
    const { id, type } = selectedColumn;

    if (type === COLUMN_TYPE.STRING || type === COLUMN_TYPE.NUMBER) {
      onSearch(id, type, word);
      setActiveFilter(`${selectedColumn.label} = ${word}`);
    }
    if (type === COLUMN_TYPE.OPTION) {
      onSearch(id, type, option);
      setActiveFilter(`${selectedColumn.label} = ${option}`);
    }
    if (type === COLUMN_TYPE.DATE) {
      onSearch(id, type, {
        startDate,
        endDate,
      });
      setActiveFilter(`${selectedColumn.label} = ${getDateString(startDate)} ~ ${getDateString(endDate)}`);
    }
  };

  return (
    <FilterWrapper>
      <Box display="flex" alignItems="center" paddingBottom="32px">
        <Box style={{ width: 200 }}>
          <ActionBarSelect
            name="column"
            options={columnOptions}
            control={control}
            rules={{ required: "Column field is required" }}
            error={errors.column}
          />
        </Box>

        {selectedColumn.type === COLUMN_TYPE.OPTION && (
          <Box style={{ width: 200 }}>
            <ActionBarSelect
              name="option"
              options={selectedColumn.options || []}
              control={control}
              error={errors.option}
            />
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.STRING && (
          <Box sx={{ width: 300, position: "relative" }}>
            <FormInput
              {...register("searchWord", { required: "Please enter text to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.NUMBER && (
          <Box sx={{ width: 300, position: "relative" }}>
            <FormInput
              {...register("searchWord", { required: "Please enter number to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}

        {selectedColumn.type === COLUMN_TYPE.DATE && (
          <DateFilterWrapper>
            <span style={{ margin: "0 10px" }}>From:</span>
            <DateTimePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} />
            <span style={{ margin: "0 10px" }}>To:</span>
            <DateTimePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} />
          </DateFilterWrapper>
        )}

        {isValidFilter && showPrevButton && (
          <ActionButton
            onClick={() => onPrevSearch?.()}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 30,
              fontSize: 11,
              textWrap: "nowrap",
            }}
          >
            Search in Prev page
          </ActionButton>
        )}

        <ActionButton
          onClick={handleSubmit(onSearchClick)}
          style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 30, fontSize: 11, textWrap: "nowrap" }}
        >
          Search
        </ActionButton>

        {isValidFilter && showNextButton && (
          <ActionButton
            onClick={() => onNextSearch?.()}
            style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 30, fontSize: 11, textWrap: "nowrap" }}
          >
            Search in Next page
          </ActionButton>
        )}
        <div style={{ marginLeft: 30 }}>
          <SmallFlatButton onClick={onResetFilter}>Reset Filter</SmallFlatButton>
        </div>
      </Box>

      <Box display="flex" flexDirection="row">
        <ActiveFilter>Active Filter: {activeFilter}</ActiveFilter>
        {isValidFilter && !!resultCount && (
          <ActiveFilter style={{ marginLeft: 30 }}>Search Results: {resultCount}</ActiveFilter>
        )}
      </Box>
    </FilterWrapper>
  );
};

TableFilterNew.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onPrevSearch: PropTypes.func,
  onNextSearch: PropTypes.func,
  showPrevButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  resultCount: PropTypes.number,
};
TableFilterNew.defaultProps = {
  onReset: null,
  onPrevSearch: () => {},
  onNextSearch: () => {},
  showPrevButton: false,
  showNextButton: false,
  resultCount: 0,
};

export default TableFilterNew;
